<template>
  <el-dialog v-model="dialogFormVisible" :before-close="handleClose" :close-on-click-modal="false" :title="title" width="909px">
    <el-tabs v-model="activeName" type="card" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="本地上传" name="first">
        <div class="upload">
          <el-alert :closable="false" :title="`支持jpg、jpeg、png格式，单次可最多选择${limit}张图片，每张不可大于${size}M，如果大于${size}M会自动为您过滤`" type="info" />
          <!-- <el-upload ref="upload" accept="image/png, image/jpeg,image/gif" action="#" :auto-upload="false" class="upload-content" :close-on-click-modal="false" :data="data" :file-list="fileList" :headers="headers" :limit="limit" list-type="picture-card" :multiple="true" :name="name"
            :on-change="handleChange" :on-error="handleError" :on-exceed="handleExceed" :on-preview="handlePreview" :on-progress="handleProgress" :on-remove="handleRemove" :on-success="handleSuccess">
            <template #trigger>
              <i class="el-icon-plus" />
            </template>
            <el-dialog v-model="dialogVisible" append-to-body title="查看大图">
              <div>
                <el-image :src="dialogImageUrl" />
              </div>
            </el-dialog>
          </el-upload> -->

          <vue-cropper ref="vabCropperRef" @imgInfo="logoInfo" :title="'品牌logo'" :option="options"  :drag-mode="'none'"/>

        </div>

      

      </el-tab-pane>

      <el-tab-pane label="本地上传(无裁剪)" name="third">
        <div class="upload">
          <el-alert :closable="false" :title="`支持jpg、jpeg、png格式，单次可最多选择${limit}张图片，每张不可大于${size}M，如果大于${size}M会自动为您过滤`" type="info" />
          <el-upload ref="upload" accept="image/png, image/jpeg,image/gif" action="#" :auto-upload="false" class="upload-content" :close-on-click-modal="false" :data="data" :file-list="fileList" :headers="headers" :limit="limit" list-type="picture-card" :multiple="true" :name="name"
            :on-change="handleChange" :on-error="handleError" :on-exceed="handleExceed" :on-preview="handlePreview" :on-progress="handleProgress" :on-remove="handleRemove" :on-success="handleSuccess">
            <template #trigger>
              <i class="el-icon-plus" />
            </template>
            <el-dialog v-model="dialogVisible" append-to-body title="查看大图">
              <div>
                <el-image :src="dialogImageUrl" />
              </div>
            </el-dialog>
          </el-upload>
        </div>
      </el-tab-pane>

      <el-tab-pane label="图片内容库" name="second">
        <el-table border :data="list">
          <el-table-column label="标题" prop="title" show-overflow-tooltip align="center" />
          <el-table-column label="图片" prop="name">
            <template #default="{ row }">
              <el-image style="width: 100px; height: 100px" :src="row.fileUrl" :preview-src-list="[row.fileUrl]" :initial-index="4" fit="cover" />
            </template>
          </el-table-column>
          <el-table-column label="标签" prop="name">
            <template #default="{ row }">
              <el-tag v-for="(item, index) in row.materialTagVos" :key="index" type='success'>{{item.tagName}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="{ row }">
              <el-button @click="chooseImg(row)">上传</el-button>
            </template>
          </el-table-column>
          <template #empty>
            <el-empty class="vab-data-empty" description="暂无数据" />
          </template>
        </el-table>
        <el-pagination background :current-page="queryForm.pageNumber" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />

      </el-tab-pane>
    </el-tabs>

    <template #footer v-if="activeName == 'third' ">
      <div style="position: relative; padding-right: 15px; text-align: right" v-if="activeName == 'first' || activeName == 'third'">
        <div v-if="show" style="position: absolute; top: 10px; left: 15px; color: #999">
          正在上传中... 当前上传成功数:{{ imgSuccessNum }}张 当前上传失败数:{{
            imgErrorNum
          }}张
        </div>
        <el-button type="primary" @click="handleClose">关闭</el-button>
        <el-button :loading="loading" size="small" style="margin-left: 10px" type="success" @click="submitUpload">
          开始上传
        </el-button>
      </div>
    </template>

  </el-dialog>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import { upLoadImg } from '@/api/active'
import {
  enterprisequeryMaterial,
  enterpriseQueryMaterialTag,
} from '@/api/content'
import VueCropper from '@/extra/VueCropperShow'
export default defineComponent({
  name: 'VabUpload',
  components: {
    VueCropper,
  },
  props: {
    url: {
      type: String,
      default: '/upload',
      required: true,
    },
    name: {
      type: String,
      default: 'file',
      required: true,
    },
    limit: {
      type: Number,
      default: 50,
      required: true,
    },
    size: {
      type: Number,
      default: 1,
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {
          
        }
      },
    },
  },
  emits: ['addImg'],
  data() {
    return {
      layout: 'prev, pager, next',
      show: false,
      loading: false,
      dialogVisible: false,
      dialogImageUrl: '',
      action: 'https://vab-unicloud-3a9da9.service.tcloudbase.com/upload',
      headers: {},
      fileList: [],
      picture: 'picture',
      imgNum: 0,
      imgSuccessNum: 0,
      imgErrorNum: 0,
      typeList: null,
      title: '上传',
      dialogFormVisible: false,
      data: {},
      staticImg: [],
      activeName: 'first',
      queryForm: {
        likeTitle: '',
        pageNum: 1,
        pageSize: 10,
        tagIds: '',
        type: 'IMG',
      },
      brandLogoOption: {
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
        info: true, // 裁剪框的大小信息
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
        fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
        canMove: true, // 上传图片是否可以移动
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        full: true, // 是否输出原图比例的截图
        enlarge: '1', // 图片根据截图框输出比例倍数
        mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
      },
      querySelectForm: { pageNum: 1, pageSize: 999 },
      list: [],
      tagList: [],
      total: 0,
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/token',
    }),
    percentage() {
      if (this.allImgNum === 0) return 0
      return _.round(this.imgNum / this.allImgNum, 2) * 100
    },
  },
  created() {
    this.headers['Authorization'] = `Bearer ${this.token}`
  },
  methods: {
    async fetchData() {
      const { data } = await enterprisequeryMaterial(this.queryForm)
      const { data: tagList } = await enterpriseQueryMaterialTag(
        this.querySelectForm
      )
      this.list = data.list
      this.total = data.total

      this.tagList = tagList.list
    },
    async submitUpload() {
      console.log(this.fileList)
      if (this.fileList.length == 0) {
        this.$baseMessage(`文件不能为空`, 'error', 'vab-hey-message-error')
        return false
      }
      let arr = []
      let upData = new FormData()
      for (let i in this.fileList) {
        upData.append('file', this.fileList[i].raw)
      }
      const { data } = await upLoadImg(upData)
      console.log(data)
      this.$emit('addImg', data)
      this.handleClose()
    },
    handleProgress() {
      this.loading = true
      this.show = true
    },
    handleChange(file, fileList) {
      if (file.size > 1048576 * this.size) {
        fileList.filter((item) => item !== file)
        this.fileList = fileList
      } else {
        this.allImgNum = fileList.length
      }
      this.fileList = fileList
      console.log(this.staticImg)
    },
    handleSuccess(response, file, fileList) {
      this.imgNum = this.imgNum + 1
      this.imgSuccessNum = this.imgSuccessNum + 1
      if (fileList.length === this.imgNum) {
        setTimeout(() => {
          this.$baseMessage(
            `上传完成! 共上传${fileList.length}张图片`,
            'success',
            'vab-hey-message-success'
          )
        }, 1000)
      }

      setTimeout(() => {
        this.loading = false
        this.show = false
      }, 1000)
    },
    handleError(err, file) {
      this.imgNum = this.imgNum + 1
      this.imgErrorNum = this.imgErrorNum + 1
      this.$baseMessage(
        `文件[${file.raw.name}]上传失败,文件大小为${_.round(
          file.raw.size / 1024,
          0
        )}KB`,
        'error',
        'vab-hey-message-error'
      )
      setTimeout(() => {
        this.loading = false
        this.show = false
      }, 1000)
    },
    handleRemove() {
      this.imgNum = this.imgNum - 1
      this.allNum = this.allNum - 1
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleExceed(files) {
      this.$baseMessage(
        `当前限制选择 ${this.limit} 个文件，本次选择了
             ${files.length}
             个文件`,
        'error',
        'vab-hey-message-error'
      )
    },
    handleShow(data) {
      this.title = '上传'
      this.data = data
      this.fetchData()
      this.dialogFormVisible = true
    },
    handleClose() {
      this.fileList = []
      this.picture = 'picture'
      this.allImgNum = 0
      this.imgNum = 0
      this.imgSuccessNum = 0
      this.imgErrorNum = 0
      this.headers['Authorization'] = `Bearer ${this.token}`
      this.dialogFormVisible = false
      this.$refs.vabCropperRef.closeDialog()
    },
    logoInfo(e) {
      this.$emit('addImg', e)
      this.handleClose()
    },
    chooseImg(data) {
      this.$emit('addImg', data.fileUrl)
      this.handleClose()
    },
  },
})
</script>

<style lang="scss" scoped>
.upload {
  height: 500px;

  .upload-content {
    .el-upload__tip {
      display: block;
      height: 30px;
      line-height: 30px;
    }

    :deep() {
      .el-upload--picture-card {
        width: 128px;
        height: 128px;
        margin: 3px 8px 8px 8px;
        border: 2px dashed #c0ccda;
      }

      .el-upload-list--picture {
        margin-bottom: 20px;
      }

      .el-upload-list--picture-card {
        .el-upload-list__item {
          width: 128px;
          height: 128px;
          margin: 3px 8px 8px 8px;
        }
      }
    }
  }
}
</style>
